@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto+Mono:wght@100..700&display=swap");

/* Markdown */
:root {
  --maincolor: red;
  --bordercl: rebeccapurple;
  --callouctcolor: dodgerblue;
  --hovercolor: navy;
  --darkMaincolor: #50fa7b;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  height: fit-content;
  color: #232333;
  font-size: 15px;
  line-height: 1.6em;
}

body {
  display: block;
}

.dark-mode {
  color: white !important;
  background-color: #000000;
}

.dark-mode button {
  color: white !important;
}

::selection {
  background: var(--maincolor);
  color: #fff;
}

p {
  font-family: "Fira Sans", sans-serif;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.5;
}

a {
  border-bottom: 3px solid var(--maincolor);
  color: inherit;
  text-decoration: none;
  /* font-family: "Roboto Mono", monospace; */
}

a:hover {
  background-color: var(--hovercolor);
  color: #fff !important;
}

button {
  border: none;
  background: none;
}

ul {
  list-style: none;
  padding-left: 2ch;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
ul li {
  font-family: "Roboto Mono", monospace;
  text-indent: -2ch;
  font-size: 1rem;
}
ul > li::before {
  content: "- ";
  font-weight: bold;
}

/* Containers */
.content {
  /* margin-bottom: 4em; */
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  padding: 0 1ch;
  word-wrap: break-word;
  overflow: hidden;
  min-height: 100vh;
}

/* Header */
header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "IBM Plex Mono", monospace;
  margin: 1em 0;
  line-height: 2.5em;
}

header .main {
  font-size: 1.3rem;
  font-weight: 500;
}

header nav a {
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 3px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 18px;
  margin-top: 1.5em;
  font-weight: 450;
  line-height: 1.7em;
  font-family: "Roboto Mono", monospace;
}

h1::before {
  color: var(--maincolor);
  content: "# ";
}
h2::before {
  color: var(--maincolor);
  content: "## ";
}
h3::before {
  color: var(--maincolor);
  content: "### ";
}
h4::before {
  color: var(--maincolor);
  content: "#### ";
}
h5::before {
  color: var(--maincolor);
  content: "##### ";
}
h6::before {
  color: var(--maincolor);
  content: "###### ";
}

h1 {
  margin-top: 2em;
}

.read-more {
  margin-bottom: 40px;
}

/* WakaTime */
.wakaTime {
  margin-top: 1em;
  font-family: "Roboto Mono", monospace;
}

.wakaTime p {
  font-family: "Roboto Mono", monospace;
  font-weight: 450;
  font-size: 0.9rem;
  margin-top: 5px;
  margin-bottom: 5px;
}

.wakaTime table,
.wakaTime th,
.wakaTime td {
  border: none;
  font-size: 0.8rem;
  padding-left: 5px;
  padding-right: 5px;
}

.wakaTime .plot-text {
  font-family: "Roboto Mono", monospace;
}

@media screen and (max-width: 600px) {
  .wakaTime .lang-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 70px;
  }
}

.wakaTime .date {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Projects */
.projects p {
  margin-top: 10px;
  margin-bottom: 0;
}

/* Footer */
footer {
  font-family: "Roboto Mono", monospace;
  border-top: 3px dotted var(--bordercl);
  padding: 1rem 0rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  text-align: center;
}

footer a {
  padding-bottom: 4px;
}

footer img {
  height: 1rem;
  width: 1rem;
}

/* Common */
.title h1 {
  margin-bottom: 0;
}

time {
  color: grey;
  font-family: "Roboto Mono", monospace;
}

svg {
  max-height: 15px;
}

.description {
  font-family: "Roboto Mono", monospace;
  font-size: 1rem;
  margin-top: 5px;
  margin-bottom: 10px;
}

.read-more-button {
  font-family: "Roboto Mono", monospace;
}

/* Figures and Images */
img {
  /* border: 3px solid #ececec; */
  max-width: 100%;
}

figure {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  max-width: 100%;
}

/* figure img {
  max-height: 500px;
} */

@media screen and (min-width: 600px) {
  figure {
    padding: 0 40px;
  }
}

figure h4 {
  font-size: 1rem;
  margin: 0;
  margin-bottom: 1em;
}
figure h4::before {
  content: "↳ ";
}

.canvas-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

canvas {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100vw;
  max-height: 100vh;
  /* position: fixed;
  max-width: 100%;
  height: auto;
   border: 5px solid white; */
}
